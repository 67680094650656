import { getCookie } from './cookieUtils'

export interface ContactData {
  firstName: string
  lastName: string
  companyName: string
  email: string
  numberOfDevs?: string
  sourceCodeHosting?: string
  issueTrackers?: string[]
  ciProviders?: string[]
  campaign?: string
  howDidYouHearAboutUs?: string
}

export async function sendContactDataToHS(data: Partial<ContactData>, formId) {
  return sendFormToHS(transformContactDataToHSFormat(data), formId)
}

const recaptchaSiteKey = '6LcuiCEqAAAAAIq31g0fnnwjrgttihtdAydVmMS8'

export async function sendFormToHS(fields: HSFormField[], formId) {
  await new Promise(resolve => window.grecaptcha?.ready(() => resolve(true)))
  await window.grecaptcha?.execute(recaptchaSiteKey, { action: 'submit' })

  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/20011531/${formId}`
  const cookieValue = getHubSpotCookieValue()
  const requestBody = {
    submittedAt: Date.now(),
    fields,
    ...(cookieValue !== undefined && {
      context: { hutk: cookieValue },
    }),
  }
  return fetch(postUrl, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: { 'Content-Type': 'application/json' },
  })
}

export interface HSFormField {
  name: string
  value?: string | string[]
}

function getHubSpotCookieValue(): string | undefined {
  return getCookie('hubspotutk')
}

function transformContactDataToHSFormat(
  data: Partial<ContactData>,
): HSFormField[] {
  return [
    {
      name: 'firstname',
      value: data.firstName,
    },
    {
      name: 'lastname',
      value: data.lastName,
    },
    {
      name: 'email',
      value: data.email,
    },
    {
      name: 'company',
      value: data.companyName,
    },
    {
      name: 'number_of_developers',
      value: data.numberOfDevs,
    },
    {
      name: 'source_code_hosting',
      value: data.sourceCodeHosting,
    },
    {
      name: 'issue_tracker',
      value: data.issueTrackers?.join(','),
    },
    { name: 'campaign', value: data.campaign },
    { name: 'ci_cd', value: data.ciProviders?.join(';') },
    { name: 'how_did_you_hear_about_us', value: data.howDidYouHearAboutUs },
  ].filter(item => item.value !== undefined)
}
